// BlogDetailPage.tsx
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useParams, Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram, FaWhatsapp} from 'react-icons/fa';
import { publishedBlogs } from '../data/blogData';
//import { Blog } from '../types';
import { formatDateLong } from '../utils/dateFormatter';
import { boldFirstSentence } from '../utils/boldFirstSentence';

const BlogDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const blog = publishedBlogs.find((b) => b.id === id);

  if (!blog) {
    return <div className="container mx-auto px-4 py-8">Blog not found</div>;
  }


  const TableOfContents = () => (
    <div className="mb-8 lg:mb-0">
      <h3 className="text-xl font-semibold mb-4">Table of Contents</h3>
      <ul className="space-y-2">
        {blog.content.map((section) => (
          <li key={section.id}>
            <a href={`#${section.id}`} className="text-blue-500 hover:underline">
              {section.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );

  const ShareLinks: React.FC = () => {
    const currentUrl = encodeURIComponent(window.location.href);
    const pageTitle = encodeURIComponent(blog.title);

    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`;
    const twitterShareUrl = `https://twitter.com/intent/tweet?url=${currentUrl}&text=${pageTitle}`;
    const linkedInShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${currentUrl}&title=${pageTitle}`;
    const whatsappShareUrl = `https://wa.me/?text=${currentUrl}`;
    const instagramShareUrl = `https://www.instagram.com/`; // Instagram doesn't support direct URL sharing

    return (
      <div className="mt-8 lg:mt-4">
        <h3 className="text-xl font-semibold mb-4">Share</h3>
        <div className="flex space-x-4">
          <a href={linkedInShareUrl} target="_blank" rel="noopener noreferrer" className="text-blue-700 hover:text-blue-900">
            <FaLinkedinIn size={24} />
          </a>
          <a href={twitterShareUrl} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-600">
            <FaTwitter size={24} />
          </a>
          <a href={facebookShareUrl} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
            <FaFacebookF size={24} />
          </a>      
          <a href={instagramShareUrl} target="_blank" rel="noopener noreferrer" className="text-pink-500 hover:text-pink-700">
            <FaInstagram size={24} />
          </a>
          <a href={whatsappShareUrl} target="_blank" rel="noopener noreferrer" className="text-green-500 hover:text-green-700">
            <FaWhatsapp size={24} />
          </a>
        </div>
      </div>
    );
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <Link to="/blogs" className="text-blue-500 hover:underline mb-4 inline-block">
        ← Back to Blogs
      </Link>
      <div className="lg:flex lg:gap-8">
        <div className="lg:w-3/4">
          <img src={blog.image} alt={blog.title} className="w-full h-64 object-cover rounded-lg mb-8" />
          <h1 className="text-4xl font-bold text-primary mb-4">{blog.title}</h1>
          <div className="flex justify-between items-center mb-8">
            <p className="text-gray-600 font-semibold">{blog.author}</p>
            <p className="text-gray-600 font-semibold">{formatDateLong(blog.date)}</p>
          </div>
          <div className="lg:hidden">
            <TableOfContents />
          </div>
          {blog.content.map((section) => (
            <div key={section.id} id={section.id} className="mb-8">
              <h2 className="text-2xl font-semibold text-primary mb-4">{section.title}</h2>
              <ReactMarkdown className="markdown-content text-gray-700 text-justify mb-4" remarkPlugins={[remarkGfm]} components={{
                img: ({node, ...props}) => (
                  <div className="intext-image-container">
                    <img {...props} className="intext-image" alt={section.title}/>
                  </div>
                )
              }}>
                {boldFirstSentence(section.content)}
              </ReactMarkdown>
              {section.image && (
                <img src={section.image} alt={section.title} className="w-full h-auto rounded-lg mb-4" />
              )}
            </div>
          ))}
        </div>
        <div className="lg:w-1/4">
          <div className="hidden lg:block lg:sticky lg:top-8">
            <TableOfContents />
            <ShareLinks />
          </div>
        </div>        
      </div>
      <div className="lg:hidden mt-8">
        <ShareLinks />
      </div>
    </div>
  );
};

export default BlogDetailPage;