import React from 'react';
//import CarouselHeader from '../components/CarouselHeader';
import SEO from '../components/SEO';

const approaches = [
  {
    name: 'B2B SaaS Products',
    image: '/images/b2b_saas.jfif',
    desc: `We develop cutting-edge B2B SaaS products designed to streamline operations, enhance efficiency, and drive innovation within specific industries. Our scalable solutions offer flexibility, foster collaboration, and enable data-driven decision making. With robust integration capabilities and continuous updates, our platforms evolve with your business needs, ensuring long-term value and competitiveness.`,
    action: 'View our Products',
    url:'/products'
  },
  {
    name: 'Business Consulting',
    image: '/images/business_consulting.jfif',
    desc: `Our experienced team delivers strategic insights and operational expertise across key areas: operational excellence, customer experience, organizational strategy, P&L management, and analytics. We help businesses optimize processes, enhance decision-making, and achieve sustainable growth. By leveraging data-driven approaches, we empower organizations to transform their operations and stay competitive in dynamic markets.`,
    action:'View Our Consulting Offerings',
    url: 'consulting'
  },
  {
    name: 'Executive Coaching',
    image: '/images/exec_coaching.jfif',
    desc: `We offer tailored coaching programs for C-suite executives and senior leadership, designed to elevate both individual and organizational competence. By blending leadership theory with industry insights, we enhance strategic thinking and change management capabilities, empowering leaders to navigate complex challenges and drive innovation in today's dynamic business landscape.`,
    action: 'Connect with Us Now',
    url: '/contact'
  },
];


const About: React.FC = () => {
  return (
  <> 
    <SEO
        title="CyfrOne - B2B SaaS Products and Business Consulting"
        description="We build B2B SaaS products and provide expert business consulting services."
      />
    <div className="bg-gray-100 min-h-screen">
      {/*<CarouselHeader />*/}
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8 space-y-8 sm:space-y-12">
        <section className="bg-white shadow-md rounded-lg p-4 sm:p-6">
          <h1 className="text-2xl sm:text-3xl font-bold mb-3 sm:mb-4 text-primary text-center">About CyfrOne</h1>
          <p className="text-sm sm:text-base text-gray-700 text-justify">
            CyfrOne is built on the philosophy of moving from Zero to One, representing a significant leap from nothing to something remarkable. This concept drives our mission to create solutions that are innovative and built to last.
          </p>
          <p className="text-sm sm:text-base text-gray-700 text-justify">
            Our name, combining "Cypher" (Zero) and "One," symbolizes this journey from inception to transformation. We are catalysts for significant change, committed to addressing complex business challenges across industries.
          </p>
          <p className="text-sm sm:text-base text-gray-700 text-justify">
            Our foundation is rooted in first principles thinking, allowing us to approach each problem with fresh perspectives and novel approaches. By leveraging cutting-edge technology and strategic insights, we strive to shape the future of business transformation.
          </p>
        </section>
        
        <section className="bg-white shadow-md rounded-lg p-4 sm:p-6">
          <h2 className="text-xl sm:text-2xl font-bold mb-3 sm:mb-4 text-primary text-center">The Founders' Story</h2>
          <div className="flex justify-center mb-4 sm:mb-6">
            <img 
              src="/images/about_us.webp"
              alt="CyfrOne Founders" 
              className="w-full max-w-md rounded-lg shadow-md"
            />
          </div>
          <p className="text-sm sm:text-base text-gray-700 mb-3 sm:mb-4 text-justify">
            CyfrOne, founded in 2023 by Riaz Mohammed and Tabish Imam, was inspired by their shared experiences in tackling complex, large-scale challenges across industries. 
          </p>
          <p className="text-sm sm:text-base text-gray-700 mb-3 sm:mb-4 text-justify">
            Recognizing the need for innovative, multifaceted solutions to address root causes of persistent business problems, they developed CyfrOne's distinctive multi-pronged approach. This combines cutting-edge software solutions, strategic business consulting, and targeted executive coaching.
          </p>
          <p className="text-sm sm:text-base text-gray-700 mb-3 sm:mb-4 text-justify">
            Today, the founders' vision has evolved into a dynamic organization that catalyzes business transformation. CyfrOne continues to grow, guided by Zero to One Philosphy and First Principles Methodology, and an unwavering focus on creating lasting value for clients across diverse industries.
          </p>
          <div className="flex justify-center mt-4">
            <a href="/leadership-team#founder-profiles"
              target="_blank" rel="noopener noreferrer"
              className="bg-primary text-white font-semibold py-2 px-6 rounded-full text-lg hover:bg-blue-700 transition duration-300"
              onClick={(e) => {
                e.preventDefault();
                window.location.href = '/leadership-team#founder-profiles';
                setTimeout(() => {
                  const element = document.getElementById('founder-profiles');
                  element?.scrollIntoView({ behavior: 'smooth' });
                }, 100);
              }}
            >View Founder Profiles</a>
          </div>
        </section>

        <section className="bg-white shadow-md rounded-lg p-4 sm:p-6">
          <h2 className="text-xl sm:text-2xl font-bold mb-3 sm:mb-4 text-primary text-center">Our Approach to Solving Industry Problems</h2>
          <p className="text-sm sm:text-base text-gray-700 mb-3 sm:mb-4 text-justify">
          At CyfrOne, we recognize that each industry faces unique challenges. Our multi-pronged solution model is tailored to address broader as well as specific needs and gaps within each industry, offering.
          </p>
          {approaches.map((approach, index) => (
            <React.Fragment key={approach.name}>
              <div key={approach.name} className={`flex flex-col md:flex-row items-center mb-16 ${index % 2 === 1 ? 'md:flex-row-reverse' : ''}`}>
                <div className="w-full md:w-1/2 mb-8 md:mb-0">
                  <img src={approach.image} alt={approach.name} className="w-80 h-80 mx-auto object-cover" />
                </div>
                <div className="w-full md:w-1/2 md:px-8">
                  <div className="flex items-center mb-4">
                    <h3 className="text-2xl text-primary font-semibold mr-2">{approach.name}</h3>                    
                  </div>
                  <p className="text-justify text-gray-700 whitespace-pre-line">{approach.desc}</p>
                </div>                
              </div>
              <div className="flex justify-center mt-4"> {/* New container for button */}
                <a href={approach.url} 
                  target="_blank" rel="noopener noreferrer"
                  className="bg-primary text-white font-semibold py-2 px-6 rounded-full text-lg hover:bg-blue-700 transition duration-300"
                >{approach.action}</a>
              </div>
              {index < approaches.length - 1 && (
                <hr className="my-16 border-t border-gray-300" />
              )}
            </React.Fragment>
          ))}         
          <p className="text-sm sm:text-base text-gray-700 py-6 mb-3 sm:mb-4 text-justify">
            We also realize ‘no one-size-fits-all’ answer to complex business problems, hence we build bespoke solutions to align with each client's specific needs and goals. At CyfrOne, we're partners in your journey towards operational excellence and business transformation, continually evolving our approach to meet the changing needs of industries.
          </p>
        </section> 
      </div>    
    </div>
  </> 
  );
};

export default About;