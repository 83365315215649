import React from 'react';
import { FaLinkedin, FaYoutube } from 'react-icons/fa';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-gray-800 text-white">
      <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4 md:mb-0">
            <p>&copy; {currentYear} CYFR1 Business Technology Solutions Pvt. Ltd. All rights reserved.</p>
          </div>
          <div className="flex space-x-6">
            <a href="https://www.linkedin.com/company/cyfrone/" target="_blank" rel="noopener noreferrer"   className="transition-colors duration-300">
              <span className="sr-only">LinkedIn</span>
              <FaLinkedin className="h-6 w-6" style={{ color: '#0077b5' }} />
            </a>
            <a href="https://www.youtube.com/@CyfrOne" target="_blank" rel="noopener noreferrer" className="transition-colors duration-300">
              <span className="sr-only">YouTube</span>
              <FaYoutube className="h-6 w-6" style={{ color: '#ff0000' }} />
            </a>
            {/*<a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="transition-colors duration-300">
              <span className="sr-only">Facebook</span>
              <FaFacebook className="h-6 w-6" style={{ color: '#3b5998' }} />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="transition-colors duration-300">
              <span className="sr-only">Twitter</span>
              <FaTwitter className="h-6 w-6" style={{ color: '#1da1f2' }} />
            </a>            
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="transition-colors duration-300">
              <span className="sr-only">Instagram</span>
              <FaInstagram className="h-6 w-6" style={{ color: '#e1306c' }} />
            </a>*/}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
