import React, { useState, ChangeEvent, FormEvent } from 'react';
import emailjs from '@emailjs/browser';

interface ContactFormData {
  name: string;
  email: string;
  phone?: string;
  subject: string;
  message: string;
  purpose: 'Consulting' | 'Products' | 'Products - OASIS' | 'Executive Coaching' | 'Others';
}

const initialFormData: ContactFormData = {
  name: '',
  email: '',
  phone: '',
  subject: '',
  message: '',
  purpose: 'Consulting',
};

const ContactForm: React.FC = () => {
  const [formData, setFormData] = useState<ContactFormData>(initialFormData);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const templateParams: Record<string, unknown> = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        subject: formData.subject,
        message: formData.message,
        purpose: formData.purpose,
      };

      const result = await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID!,
        process.env.REACT_APP_EMAILJS_INBOUND_TEMPLATE_ID!,
        templateParams,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY!
      );
      console.log(result.text);
      alert('Email sent successfully!');
      setFormData(initialFormData); // Clear the form
    } catch (error) {
      console.log(error);
      alert('Failed to send the email. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <input
        type="text"
        name="name"
        placeholder="Name"
        value={formData.name}
        onChange={handleChange}
        className="w-full p-2 border border-gray-300 rounded"
        required
        disabled={isLoading}
      />
      <input
        type="email"
        name="email"
        placeholder="Email"
        value={formData.email}
        onChange={handleChange}
        className="w-full p-2 border border-gray-300 rounded"
        required
        disabled={isLoading}
      />
      <input
        type="tel"
        name="phone"
        placeholder="Phone No"
        value={formData.phone}
        onChange={handleChange}
        className="w-full p-2 border border-gray-300 rounded"
        disabled={isLoading}
      />
      <div>
        <label htmlFor="purpose" className="font-bold">Purpose</label>
        <select
          name="purpose"
          value={formData.purpose}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          required
          disabled={isLoading}
        >
          <option value="Consulting">Consulting</option>
          <option value="Products">Products</option>
          <option value="Products - OASIS">Products - OASIS</option>
          <option value="Executive Coaching">Executive Coaching</option>
          <option value="Others">Others - Please Specify in Subject</option>
        </select>
      </div>
      <input
        type="text"
        name="subject"
        placeholder="Subject"
        value={formData.subject}
        onChange={handleChange}
        className="w-full p-2 border border-gray-300 rounded"
        required
        disabled={isLoading}
      />
      <textarea
        name="message"
        placeholder="Body Text"
        value={formData.message}
        onChange={handleChange}
        className="w-full p-2 border border-gray-300 rounded"
        rows={6}
        required
        disabled={isLoading}
      ></textarea>
      <button
        type="submit"
        className="w-full bg-blue-500 text-white font-bold p-2 rounded hover:bg-blue-700 transition duration-200 flex items-center justify-center"
        disabled={isLoading}
      >
        {isLoading ? (
          <>
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Sending...
          </>
        ) : (
          'Submit'
        )}
      </button>      
    </form>
  );
};

export default ContactForm;