import React, {useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Layout from './components/Layout';
//import Home from './pages/Home';
import About from './pages/About';
import LeadershipTeam from './pages/LeadershipTeam';
import Products from './pages/Products';
import Consulting from './pages/Consulting';
import Blogs from './pages/Blogs';
import BlogDetailPage from './pages/BlogDetailPage';
import Contact from './pages/Contact';


const App: React.FC = () => {

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      setTimeout(() => {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
  }, []);

  return (
    <HelmetProvider>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<About />} />
            <Route path="/about" element={<About />} />
            <Route path="/leadership-team" element={<LeadershipTeam />} />
            <Route path="/products" element={<Products />} />
            <Route path="/consulting" element={<Consulting />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blog/:id" element={<BlogDetailPage />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </Layout>
      </Router>
    </HelmetProvider>
  );
};

export default App;
