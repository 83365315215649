import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './CarouselHeader.css'; // Make sure this file exists with the necessary styles

const CarouselHeader: React.FC = () => {
  const sliderRef = useRef<Slider | null>(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: false,
    verticalSwiping: false,
    autoplay: true,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
    cssEase: 'linear',
  };

  const slides = [
    {
      title: "Our Vision",
      content: "To build enduring solutions for deep rooted business problems from the first principles."
    },
    {
      title: "Our Mission",
      content: "To Provide solutions by leveraging technology & leadership experience for businesses to raise the bar on customer experience, unlock hidden value and realize true potential."
    }
  ];

  return (
    <div className="py-6">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"> {/* Adjust height as needed */}
        <Slider ref={sliderRef} {...settings} className="carousel-container">
          {slides.map((slide, index) => (
            <div key={index} className="carousel-slide">
              <div className="text-center">
                <h2 className="text-3xl font-bold text-background sm:text-4xl mb-4"> {/* Increased font size */}
                  {slide.title}
                </h2>
                <p className="text-xl text-background/80 sm:text-2xl"> {/* Increased font size */}
                  {slide.content}
                </p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default CarouselHeader;
