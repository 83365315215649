import React from 'react';

// Define the type for a partner
interface Partner {
  name: string;
  logo: string;
  website: string;
}

// Sample partner data (you can replace this with your actual data)
const partners: Partner[] = [
  { name: '100 Folds Academy', logo: '/images/100Folds_WhiteBG.png', website: 'https://www.100foldsacademy.com' },
  { name: 'Zuci', logo: '/images/zuci_logo.jfif', website: 'https://online.zuci.in' },
  // Add more partners as needed
];

const ConsultingPartners: React.FC = () => {
  return (
    <section className="py-10">
      <h2 className="text-3xl font-bold text-primary text-center mb-8">Our Past Consulting Assignments</h2>
      <div className="flex flex-wrap justify-center items-center gap-8">
        {partners.map((partner) => (
          <a
            key={partner.name}
            href={partner.website}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center w-40 h-40 bg-white shadow-md rounded-lg transition-transform hover:scale-105"
          >
            <img
              src={partner.logo}
              alt={`${partner.name} logo`}
              className="max-w-full max-h-full object-contain p-2"
            />
          </a>
        ))}
      </div>
    </section>
  );
};

export default ConsultingPartners;