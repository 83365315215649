import React from 'react';

interface TestimonialCardProps {
  name: string;
  title: string;
  image: string;
  quote: string;
}

const TestimonialCard: React.FC<TestimonialCardProps> = ({ name, title, image, quote }) => {
  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <p className="text-gray-800 text-justify">"{quote}"</p>
      <br />
      <div className="flex items-center mb-4">
        <img src={image} alt={name} className="w-16 h-16 rounded-full mr-4" />
        <div>
          <h3 className="text-lg font-bold">{name}</h3>
          <p className="text-gray-600">{title}</p>
        </div>
      </div>      
    </div>
  );
};

export default TestimonialCard;