import React from 'react';
import { FaLinkedin } from 'react-icons/fa';

// Define an interface for team member data
interface TeamMember {
  id: number;
  name: string;
  expertise: string;
  designation: string;
  image: string;
  profileLink: string;
  linkedIn: string;
}

const ConsultingTeamSection: React.FC = () => {
  // Define an array of team members
  const teamMembers: TeamMember[] = [
    {
      id: 1,
      name: "Riazuddin Mohammed",
      expertise: "Strategy & Operations",
      designation: "CoFounder, Sr. Principal Consultant",
      image: "/images/riaz_consult_small.jpg",
      profileLink: "/leadership-team#founder-profiles",
      linkedIn: "https://www.linkedin.com/in/riazuddin-mohammed-a810108/"
    },
    {
      id: 2,
      name: "Tabish Imam",
      expertise: "Technology & Innovation",
      designation: "CoFounder, Sr. Principal Consultant",
      image: "/images/tabish_consult_small.jpg",
      profileLink: "/leadership-team#founder-profiles",
      linkedIn: "https://www.linkedin.com/in/tabishimam/"  // Make sure this image exists
    }
    // Add more team members as needed
  ];

  return (
    <section className="py-10 bg-gray-100">
      <h2 className="text-3xl font-bold mb-12 text-center text-primary">Our Principal Consulting Partners</h2>
      <div className="flex flex-wrap justify-center">
        {teamMembers.map((member) => (
          <div key={member.id} className="w-64 m-8 text-center">
            <img
              src={member.image}
              alt={`${member.name}`}
              className="rounded-full mx-auto mb-4 w-40 h-40 object-cover"  // Added fixed dimensions and object-cover
            />
            <p className="font-semibold flex items-center justify-center">
              <a href={member.profileLink} target="_blank" rel="noopener noreferrer" className="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-4 rounded inline-block">{member.name}</a>
              <a href={member.linkedIn} target="_blank" rel="noopener noreferrer" className="ml-2">
                <div className="rounded-full overflow-hidden">
                  <FaLinkedin size={30} className="text-blue-700 rounded-full" />
                </div>
              </a>
            </p>

            <h5 className="font-semibold">{member.designation}</h5>
            <p className="text-sm text-gray-600">{member.expertise}</p>            
          </div>
        ))}
      </div>
      <br />
      <div className="text-center">
        <a href="/contact" // Replace with your actual contact us page URL
          target="_blank" rel="noopener noreferrer"
          className="bg-primary text-white font-semibold py-2 px-6 rounded-full text-lg hover:bg-primary-dark transition duration-300"
        >
          Consult Now
        </a>
      </div>
    </section>
  );
};

export default ConsultingTeamSection;