import React from 'react';

const ConsultingHero: React.FC = () => {
  return (
    <section className="py-10 bg-gray-100">
      <div className="text-center">
        <h1 className="text-4xl text-primary font-bold mb-4">Business Consulting</h1>
        <p className="text-xl mb-8">
        CyfrOne offers Business Consulting services focussed on Operational Excellence, Customer Experience, Organizational Strategy, P&L Management, Analytics, and Executive Leadership development. <a href="#cslist" className="text-primary font-semi-bold hover:text-primary-700">More details below ...</a></p>
        <p className="text-xl mb-8">
        Our Strategy through these consulting assignments will be to use first principles methodology and work backwards from the end-customer. While the team carries diverse experience and expertise across industries, we also believe that fundamental methods of problem solving are key to address core problems for any industry or organization.
        </p>        
        <a href="/contact" // Replace with your actual contact us page URL
          className="bg-primary text-white font-semibold py-2 px-6 rounded-full text-lg hover:bg-blue-700 transition duration-300"
        >
          Get Started
        </a>
      </div>
    </section>
  );
};

export default ConsultingHero;