// src/utils/boldFirstSentence.ts

export const boldFirstSentence = (content: string): string => {
  return content
    .split('\n') // Split content into lines
    .map((line) => {
      // Check if the line is a bullet point
      if (line.trim().startsWith('*')) {
        const bulletContent = line.substring(line.indexOf('*') + 1).trim();
        const colonIndex = bulletContent.indexOf(':');
        
        if (colonIndex !== -1) {
          // If there's a colon, bold everything before it
          const beforeColon = bulletContent.slice(0, colonIndex + 1);
          const afterColon = bulletContent.slice(colonIndex + 1);
          return `* **${beforeColon}**${afterColon}`;
        } else {
          // If there's no colon, return the line as is
          return line;
        }
      } else {
        // For non-bulleted lines, use the original sentence bolding logic
        //const [firstSentence, ...rest] = line.split(/(?<=[.!?])\s+/);
        return line;
      }
    })
    .join('\n'); // Join the lines back together
};