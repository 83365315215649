import React from "react";
import { FaLinkedin } from "react-icons/fa";

const founders = [
  {
    name: "Riaz",
    image: "/images/riaz_consult.webp",
    bio: `Riaz brings over 20 years of global leadership experience in Operations, Programs, and Entrepreneurship with Amazon, Bank of America, and General Electric. He has developed expertise in E-Commerce, Supply Chain, Tech Support, Customer Service, and Offshore business. Most recently, he held an executive position at Amazon, leading World Wide Operations, Programs, Workforce Management, Analytics, Quality, and Training functions in the Middle Mile for Amazon Transportation Services.

    Riaz began his career as an entrepreneur after earning an MBA in Finance from Osmania University in 2002, becoming an Airtel Franchisee partner with Bharti Group. He is recognized for his thought leadership, long-term vision, and ability to build and lead global teams. He has managed large-scale World Wide teams, successfully owned P&Ls, and served as an executive Bar Raiser and Diversity champion at Amazon.

    A builder at heart, Riaz believes in disruptive thinking for solving problems and has a passion for Organizational and Leadership development. His affinity for travel has enhanced his cultural intelligence, making him a highly effective global leader.`,
    linkedIn: "https://www.linkedin.com/in/riazuddin-mohammed-a810108/",
  },
  {
    name: "Tabish",
    image: "/images/tabish_consult.webp",
    bio: `Tabish, an IIT Kharagpur and IIM Bangalore alumnus, began his career with a technology consulting startup, iRunway, where he provided technical expertise on marquee patent litigation and portfolio analysis projects. He then moved to Tata Steel Ltd, where he led IT supply chain expansion projects and gained valuable experience in core operations and lean manufacturing principles.

    After completing his MBA in Strategy & Operations from IIM Bangalore, Tabish joined the Amazon Pathways Operations Leadership Program. He initially led the site expansion for Amazon Devices and Digital products in Hyderabad, and later managed the expansion of Shipping & Delivery Support teams across India and the Philippines. His work at Amazon focused on enhancing operational efficiency and customer experience.

    Throughout his career, Tabish has built high-performing teams and developed leaders, leveraging his critical and analytical thinking skills to solve complex problems and deliver sustainable results. Passionate about technology, problem-solving, leadership development, learning new skills, and sports, he consistently strives for excellence in all his endeavors.`,
    linkedIn: "https://www.linkedin.com/in/tabishimam/",
  },
];

const LeadershipTeamFounderProfiles: React.FC = () => {
  return (
    <section className="py-5">
      <h1 className="text-4xl font-bold text-primary mb-12 text-center">
        Our Founders
      </h1>
      {founders.map((founder, index) => (
        <React.Fragment key={founder.name}>
          <div
            key={founder.name}
            className={`flex flex-col md:flex-row items-center mb-16 ${
              index % 2 === 1 ? "md:flex-row-reverse" : ""
            }`}
          >
            <div className="w-full md:w-2/5 mb-8 md:mb-0">
              <img
                src={founder.image}
                alt={founder.name}
                className="w-80 h-120 mx-auto object-cover"
              />
            </div>
            <div className="w-full md:w-3/5 md:px-8">
              <div className="flex items-center mb-4">
                <h3 className="text-2xl font-semibold mr-2">{founder.name}</h3>
                <a
                  href={founder.linkedIn}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:text-blue-800"
                >
                  <FaLinkedin size={24} />
                </a>
              </div>
              <p className="text-justify text-gray-700 whitespace-pre-line">
                {founder.bio}
              </p>
            </div>
          </div>
          {index < founders.length - 1 && (
            <hr className="my-16 border-t border-gray-300" />
          )}
        </React.Fragment>
      ))}
    </section>
  );
};

export default LeadershipTeamFounderProfiles;
