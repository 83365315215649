import React, { useState } from 'react';
import { Link } from 'react-router-dom';
//import { Blog } from '../types'
import { publishedBlogs } from '../data/blogData';
import { formatDate } from '../utils/dateFormatter';

const Blogs: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 6;

  // Sort blogs by date, latest first
  const sortedBlogs = [...publishedBlogs].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = sortedBlogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);



  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-8 text-primary text-center">Our Blogs</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {currentBlogs.map((blog) => (
          <Link key={blog.id} to={`/blog/${blog.id}`} className="block">
            <div className="bg-white rounded-lg shadow-md overflow-hidden transition duration-300 ease-in-out transform hover:scale-105">
              {/* Image Section */}
              <div className="relative h-36">
                <img
                  src={blog.image}
                  alt={blog.title}
                  className="w-full h-full object-cover object-center"
                />
              </div>
               {/* Text Section */}
              <div className="p-4">
                <h2 className="text-xl font-semibold mb-2">{blog.title}</h2>
                <p className="text-gray-600 mb-4">{blog.summary}</p>
                <div className="flex justify-between items-center">
                  <p className="text-sm text-gray-500 font-semibold">{blog.author}</p>
                  <p className="text-sm text-gray-500 font-semibold">{formatDate(blog.date)}</p>
                </div>
                <div className="mt-4">
                  {blog.tags.map((tag) => (
                    <span
                      key={tag}
                      className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
      <div className="mt-8 flex justify-center">
        {Array.from({ length: Math.ceil(sortedBlogs.length / blogsPerPage) }, (_, i) => (
          <button
            key={i}
            onClick={() => paginate(i + 1)}
            className={`mx-1 px-3 py-1 rounded ${
              currentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 hover:bg-gray-300'
            }`}
          >
            {i + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Blogs;