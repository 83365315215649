import React from 'react';

const services = [
  {
    title: 'Operational Excellence',
    description: 'Streamline operations to enhance efficiency and reduce costs. Our team of experts will work with you to identify inefficiencies, develop streamlined processes, and implement best practices that drive operational excellence. By analyzing your current operations, we can uncover hidden opportunities for improvement and implement solutions that enhance productivity and reduce operational costs.',
    icon: '⚙️',
  },
  {
    title: 'Customer Experience',
    description: 'Develop strategies to improve customer satisfaction and loyalty. We understand that exceptional customer experience is key to success, and our solutions are designed to help you exceed customer expectations at every touchpoint. Our approach involves analyzing customer feedback, understanding their needs, and designing strategies that enhance their overall experience with your brand.',
    icon: '😊',
  },
  {
    title: 'Organizational Strategy',
    description: 'Craft robust strategies that align with your business goals. Our strategic planning services will help you define clear objectives, develop actionable plans, and achieve sustainable growth. We work closely with your leadership team to understand your vision and create a roadmap that drives your organization towards its goals.',
    icon: '🎯',
  },
  {
    title: 'P&L Management',
    description: 'Optimize your profit and loss management for better financial performance. We provide insights and tools to help you manage your finances effectively, ensuring profitability and long-term success. Our P&L management services include budgeting, forecasting, and financial analysis to help you make informed decisions that drive profitability.',
    icon: '💰',
  },
  {
    title: 'Analytics',
    description: 'Leverage data to gain insights and drive informed decision-making. Our analytics services provide you with the information you need to understand your business performance and make data-driven decisions. From customer behavior analysis to operational metrics, we offer comprehensive analytics solutions that help you uncover trends and identify areas for improvement.',
    icon: '📊',
  },
  {
    title: 'Executive Leadership Development',
    description: 'Cultivate leadership skills and capabilities within your organization. We offer leadership development programs that are tailored to your needs, helping you build a strong leadership team that drives success. Our programs focus on developing the skills and competencies needed to lead effectively in today\'s complex business environment.',
    icon: '👥',
  },
];

const ConsultingServicesList: React.FC = () => {
  return (
    <section id="cslist" className="py-10">
      <h2 className="text-3xl font-bold mb-12 text-center text-primary">Our Consulting Services</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {services.map((service, index) => (
          <div key={index} className="bg-white p-6 rounded-lg shadow-md">
            <div className="text-4xl mb-4">{service.icon}</div>
            <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
            <p className="text-justify">{service.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ConsultingServicesList;