import React from 'react';
import ReactPlayer from 'react-player';
import { motion } from 'framer-motion';
import TestimonialCarousel from '../components/TestimonialCarousel';
import SEO from '../components/SEO';

const Products: React.FC = () => {
  return (
    <>
      <SEO
        title="Our Products - CyfrOne OASIS"
        description="Explore our cutting-edge B2B SaaS products designed to revolutionize your business operations."
      />
      <div className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h1 className="text-3xl font-bold text-primary sm:text-4xl text-center">
              Our Products - OASIS
            </h1>
            <p className="mt-4 max-w-3xl mx-auto text-xl text-gray-500 text-justify">
              CyfrOne OASIS (One-Stop Advanced System for Integrated Solutions) is an integrated Restaurant Management SaaS that addresses the core industry problems to maximize sales and optimize costs across the value chain of restaurants and cafes. The product focusses on the business owner's perspective enhancing customer experience, reducing people dependency and streamlining processes along with generating intelligent and actionable insights for different user groups of the product.
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="mt-12"
          >
            <div className="player-wrapper">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=UloYNIA2iLc"
                width="100%"
                height="100%"
                controls={true}
                className="react-player"
              />
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="mt-16"
          >
            <h2 className="text-2xl font-bold text-primary text-center">
              Testimonials from Industry Leaders
            </h2>
            <div className="mt-12">
              <TestimonialCarousel />
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default Products;