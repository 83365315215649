import React from 'react';
import ContactForm from '../components/ContactForm';

const Contact: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold mb-8 text-primary text-center">Let's Connect</h1>
      <p className="text-center">We would love to hear from you</p>      
      <div className="w-8/10 mx-auto bg-white p-8 rounded shadow flex justify-center flex-col md:flex-row md:items-start">
        <div className="w-full md:w-1/2 flex flex-col items-center">  {/* Text container */}
          <ContactForm />
        </div>
        <div className="w-full md:w-1/3 mx-auto md:mx-0 flex justify-center items-center">  {/* Image container */}
          <img src="/images/contact.svg" alt="Contact Us" className="mx-auto mb-4 md:mb-0" />
        </div>
      </div>
    </div>

  );
};

export default Contact;
