import React from 'react';
import LeadershipTeamHero from '../components/LeadershipTeamHero';
import LeadershipTeamFounderProfiles from '../components/LeadershipTeamFounderProfiles';
//import TeamOverview from '../components/TeamOverview';

const LeadershipTeam: React.FC = () => {
  return (
    <div className="container mx-auto px-4">
      <LeadershipTeamHero />
      <div id="founder-profiles">
        <LeadershipTeamFounderProfiles />
      </div>
      {/*<TeamOverview />*/}
    </div>
  );
};

export default LeadershipTeam;