import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { Bars3Icon as MenuIcon, XMarkIcon as XIcon } from '@heroicons/react/24/outline';

const navigation = [
  { name: 'About Us', href: '/about' },
  { name: 'Leadership Team', href: '/leadership-team' },
  { name: 'Products', href: '/products' },
  { name: 'Consulting', href: '/consulting' },
  { name: 'Blogs', href: '/blogs' },
  { name: 'Contact Us', href: '/contact' },
];

const Navigation: React.FC = () => {
  const location = useLocation();
  const isAboutOrHome = location.pathname === '/about' || location.pathname === '/';

  const isActive = (path: string) => {
    return location.pathname === path;
  };

  return (
    <Disclosure as="nav" className="shadow mb-4">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="flex-shrink-0 flex items-center">
                  <Link to="/" className="flex items-center">
                    <img
                      className="h-12 w-auto"
                      src={isAboutOrHome ? "/images/white_logo.png" : "/images/blue_logo.png"}
                      alt="CyfrOne Logo"
                    />
                  </Link>
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:space-x-8 sm:flex-grow sm:justify-end">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={`relative inline-flex items-center text-lg font-bold px-3 py-2 ${
                      isActive(item.href)
                        ? isAboutOrHome 
                          ? 'text-white border-b-2 border-white' 
                          : 'text-primary border-b-2 border-primary'
                        : isAboutOrHome 
                          ? 'text-white hover:text-gray-200' 
                          : 'text-primary hover:text-primary'
                    } `}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-text hover:text-primary hover:bg-background focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={`${
                    isActive(item.href)
                      ? 'bg-primary border-primary text-background'
                      : 'border-transparent text-text hover:bg-primary hover:border-primary hover:text-background'
                  } block pl-3 pr-4 py-2 border-l-4 text-base font-medium`}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Navigation;