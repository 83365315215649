// src/components/TestimonialCarousel.tsx
import React from 'react';
import Slider from 'react-slick';
import TestimonialCard from './TestimonialCard';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './TestimonialCarousel.module.css';

const testimonials = [
  {
    name: 'Bruno Monfils',
    title: 'COO, TadaWeb, Luxembourg',
    image: '/images/bruno.jpg',
    quote: 'CyfrOne’s solution enables hotels, restaurants and catering owners to focus on the customer experience by removing inefficiencies in the entire value chain, just with a couple of clicks. I am impressed with the simplicity of the integrated solution, which improves efficiency, aids in decision making with analytical insights and reduces food waste from ordering to inventory management. As a CEO or HoReCa owners, it feels like CyfrOne gives you access to the ultimate seamless, individual food ordering.',
  },
  {
    name: 'Shivi Shankaran',
    title: 'COO, Saks OFF 5th, USA',
    image: '/images/shivi.jpg',
    quote: 'Having personally inspected Cyfrone\'s innovative solution, I believe it will have a transformative impact on the hospitality industry. I am impressed by the platform\'s ability to make it simpler for both operators and customers. I found that its intuitive design makes eliminating inefficiencies in the value chain a breeze, requiring just a few clicks. What sets Cyfrone apart is its impact on decision-making through analytical insights from order placement to inventory management. For customers, Cyfrone OASIS has become an invaluable asset, offering a seamless and personalized food ordering experience. It\'s a game-changer that I personally endorse, recognizing its crucial role in supporting the specific needs of restaurants and hospitality establishments.',
  },  
];

const TestimonialCarousel: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    className: `max-w-5xl mx-auto ${styles.sliderCustom}`,
  };

  return (
    <div className="bg-light-blue py-12 px-4">
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="px-4">
            <TestimonialCard {...testimonial} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TestimonialCarousel;
