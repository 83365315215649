import React from 'react';
import ConsultingHero from '../components/ConsultingHero';
import ConsultingServicesList from '../components/ConsultingServicesList';
import ConsultingTeamSection from '../components/ConsultingTeamSection';
import ConsultingPartners from '../components/ConsultingPartners';

const Consulting: React.FC = () => {
  return (
    <div className="container mx-auto px-4">
      <ConsultingHero />
      <ConsultingServicesList />
      <ConsultingTeamSection />
      <ConsultingPartners />      
    </div>
  );
};

export default Consulting;