import React from 'react';

const LeadershipTeamHero: React.FC = () => {
  return (
    <section className="py-10 bg-gray-100">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-primary mb-4">Our Leadership Team</h1>
        <p className="text-xl max-w-3xl mx-auto text-justify">
        CyfrOne's success is driven by seasoned professionals with over three and half decades of combined experience across various industries, including leaders like Amazon, Bank of America, and Tata Steel. Our co-founders, Riaz Mohammed and Tabish Imam, bring a wealth of knowledge in global operations, finance, technology, and strategy. They have a proven track record of building high-performing teams and delivering exceptional results. Passionate about solving complex problems and driving innovation, Riaz and Tabish are committed to creating transformative business solutions and delivering lasting value for our clients through CyfrOne's distinctive approach.
        </p>
      </div>
    </section>
  );
};

export default LeadershipTeamHero;